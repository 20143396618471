<script>
  export default {
    methods: {
      $can(permissionName) {
        let permissions = permissionName.split(".");
        if (Permissions.indexOf(permissionName) !== -1) {
          return true;
        } else {
          //проверяем родительские права
          let prefix = "";
          let result = false;
          permissions.pop();

          for (let permission of permissions) {
            if (Permissions.indexOf(prefix + permission + ".*") !== -1) {
              
              result = true;
              break; 
            }
            prefix = prefix + permission + ".";
          }
          return result;
        }
      }
    },
  };
</script>