<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Подотчет</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-select v-model="calculationPeriod" @change="getData">
                        <el-option v-for="item in calculationPeriods" :key="item.id"
                            :label="item.period_short + ((item.closed) ? ' - закрыт' : '')" :value="item.id"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-header>

        <el-main>

            <el-table v-if="expensesData != null" :data="expensesData" row-key="id" style="width:100%" border>
                <el-table-column prop="name" label="Employee">
                </el-table-column>
                <el-table-column prop="project" label="Project">
                </el-table-column>
                <el-table-column prop="sum" label="Amount">
                </el-table-column>
                <el-table-column prop="description" label="Description">
                </el-table-column>
            </el-table>


        </el-main>
    </el-container>
</template>

<script>
import { calculationPeriods } from "../../api_connectors";
import { employees } from "../../api_connectors";
var moment = require('moment');


export default {
    name: "EmployeesExpensesIndex",
    data() {
        return {
            calculationPeriods: [],
            calculationPeriod: null,
            expensesData: null,
        };
    },
    mounted() {
        this.getCalculationPeriods();
    },
    methods: {
        getCalculationPeriods() {
            calculationPeriods
                .list()
                .then((response) => {
                    this.calculationPeriods = response.data;
                    this.calculationPeriods.forEach(element => {
                        if (element.period_short == moment().subtract(1, 'month').format('MM.YYYY')) {
                            this.calculationPeriod = element.id
                        }
                    });
                    this.getData();
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        getData() {
            if (this.calculationPeriods.find(x => x.id === this.calculationPeriod).closed) {
                console.log('getting from saved data');
                this.expensesData = this.calculationPeriods.find(x => x.id === this.calculationPeriod).employees_expenses_data
            } else {
                console.log('getting from api');
                employees
                    .getEmployeesExpenses(this.calculationPeriod)
                    .then((response) => {
                        this.expensesData = response.data;
                    })
                    .catch((error) => {
                        this.$message({
                            message: "Не удалось загрузить данные: " + error,
                            type: "error",
                            duration: 5000,
                            showClose: true,
                        });
                    });
            }
        },

    },
};
</script>

<style scoped></style>
