import Vue from "vue";
import Router from "vue-router";

import TransactionsIndex from "./components/transactions/Index";
import OccupationsIndex from "./components/occupations/Index";
import FoundersIndex from './components/founders/Index';
import ProfitIndex from './components/profit/Index';
import EmployeesExpensesIndex from './components/employeesExpenses/Index';
import LoginIndex from './components/login/Index';
import EmployeesIndex from './components/directories/EmployeesIndex';
import ProjectsIndex from './components/directories/ProjectsIndex';
import UsersIndex from './components/directories/UsersIndex';
import CalculationPeriodsIndex from './components/directories/CalculationPeriodsIndex';
import NewIndex from './components/new/Index';
import EmployeeVacationsIndex from './components/employeeVacations/Index';




Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "login",
            component: LoginIndex,
        },
        {
            path: "/new",
            name: "new",
            component: NewIndex,
        },
        {
            path: "/app/",
            name: "transactions",
            component: TransactionsIndex,
        },
        {
            path: "/app/employees-expenses",
            name: "employeesExpenses",
            component: EmployeesExpensesIndex,
        },
        {
            path: "/app/employee-vacations",
            name: "employeeVacations",
            component: EmployeeVacationsIndex,
        },
        {
            path: "/app/founders",
            name: "founders",
            component: FoundersIndex,
        },
        {
            path: "/app/profit",
            name: "profit",
            component: ProfitIndex,
        },
        {
            path: "/app/occupations",
            name: "occupations",
            component: OccupationsIndex,
        },
        {
            path: "/app/directories/employees",
            name: "employees",
            component: EmployeesIndex,
        },
        {
            path: "/app/directories/projects",
            name: "projects",
            component: ProjectsIndex,
        },
        {
            path: "/app/directories/calculation-periods",
            name: "calculation-periods",
            component: CalculationPeriodsIndex,
        },
        {
            path: "/app/directories/users",
            name: "users",
            component: UsersIndex,
        },


    ],
});
