<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Проекты</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-button v-if="$can('projects.write')" @click="newRow()" type="success"
                        icon="el-icon-plus">Создать</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>
            <el-table :data="dataRows">
                <el-table-column prop="name" label="Название">
                </el-table-column>
                <el-table-column prop="active" label="Проект активен?" :formatter="projectActiveFormatter">
                </el-table-column>
                <el-table-column prop="active" label="Участники" :formatter="stackHoldersFormatter">
                </el-table-column>
                <el-table-column label="Действия">
                    <template slot-scope="scope">
                        <el-button-group style="font-size: 20px">
                            <i v-if="$can('projects.write')" @click="editRow(scope.$index)" class="el-icon-edit"
                                style="color: blue"></i>
                            <el-popconfirm v-if="$can('projects.write')" @confirm="deleteRow(scope.$index)"
                                title="Точно хотите удалить?" confirm-button-text="Да" cancel-button-text="Нет">
                                <i slot="reference" style="color: red" class="el-icon-delete"></i>
                            </el-popconfirm>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog v-if="rowObject != null" title="Создать/Изменить" :visible.sync="createDialogVisible">
                <el-form :model="rowObject" label-width="200px">
                    <el-form-item label="Name">
                        <el-input v-model="rowObject.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Проект активен?">
                        <el-radio-group v-model="rowObject.active">
                            <el-radio-button label="1">Да</el-radio-button>
                            <el-radio-button label="0">Нет</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="Участники">
                        <div v-for="item in rowObject.stack_holders">
                            <span>{{ item.name }}</span>
                            <span><el-input v-model="item.percent"></el-input></span>
                        </div>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createDialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="saveRow()">Save</el-button>
                </span>
            </el-dialog>

        </el-main>
    </el-container>
</template>

<script>
import { projects, stackHolders } from "../../api_connectors";

export default {
    name: "ProjectsIndex",
    data() {
        return {
            dataRows: [],
            stack_holders: [],
            rowObject: null,
            createDialogVisible: false,

        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let promises = [];
            promises.push(stackHolders.list());
            promises.push(projects.extendedList());

            Promise.all(promises)
                .then((response) => {

                    this.stack_holders = response[0].data;
                    let projects = response[1].data;
                    projects.forEach(project => {
                        let stackHoldersFormatted = [];
                        this.stack_holders.forEach(stackholder => {
                            stackHoldersFormatted.push({
                                id: stackholder.id,
                                name: stackholder.name,
                                percent: (project.stack_holders.find(x => x.id === stackholder.id) === undefined) ? 0 : project.stack_holders.find(x => x.id === stackholder.id).pivot.percent,
                            })
                        });
                        project.stack_holders = stackHoldersFormatted;

                    });

                    this.dataRows = projects;
                    console.log(this.dataRows);
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        newRow() {
            let object = {
                name: null,
                active: 1,
                stack_holders: []
            };
            this.stack_holders.forEach(element => {
                object.stack_holders.push({
                    id: element.id,
                    name: element.name,
                    percent: 0
                })
            });

            this.rowObject = object;
            this.createDialogVisible = true;
        },
        editRow($index) {
            this.rowObject = this.dataRows[$index];
            this.createDialogVisible = true;
        },
        saveRow() {
            this.$loading();
            let result =
                this.rowObject.id > 0
                    ? projects.update(
                        this.rowObject.id,
                        this.rowObject
                    )
                    : projects.create(this.rowObject);
            result
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                    this.getData();
                    this.createDialogVisible = false;
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        deleteRow($index) {
            this.$loading();
            projects
                .delete(this.dataRows[$index].id)
                .then((response) => {
                    this.getData();
                    this.$loading().close();
                    this.$message({
                        message: "Успешное удаление!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        projectActiveFormatter(row, column, cellValue, index) {
            return (cellValue > 0) ? 'Да' : "Нет";
        },
        stackHoldersFormatter(row) {
            // console.log(row);
            let value = '';
            row.stack_holders.forEach((element) => {
                if (element.percent > 0) value += element.name + ' - ' + element.percent + '%; '
            });
            return value;
        }

    },
};
</script>

<style scoped></style>
