<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Доходность проектов</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-select v-model="calculationPeriod" @change="getData">
                        <el-option v-for="item in calculationPeriods" :key="item.id" :label="item.period_short"
                            :value="item.id"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-header>

        <el-main>


            <el-table :data="profitData" show-summary row-key="id" border>
                <el-table-column prop="name" label="Название">
                </el-table-column>
                <el-table-column prop="in" label="Входящие">
                </el-table-column>
                <el-table-column prop="out" label="Исходящие">
                </el-table-column>
                <el-table-column prop="profit" label="Выручка">
                </el-table-column>
            </el-table>



        </el-main>
    </el-container>
</template>

<script>
import { calculationPeriods } from "../../api_connectors";
import { projects } from "../../api_connectors";
var moment = require('moment');

export default {
    name: "ProfitIndex",
    data() {
        return {
            calculationPeriods: [],
            calculationPeriod: null,
            profitData: [],
        };
    },
    mounted() {
        this.getCalculationPeriods();
    },
    methods: {
        getCalculationPeriods() {
            calculationPeriods
                .list()
                .then((response) => {
                    this.calculationPeriods = response.data;
                    this.calculationPeriods.forEach(element => {
                        if (element.period_short == moment().subtract(1, 'month').format('MM.YYYY')) {
                            this.calculationPeriod = element.id
                        }
                    });
                    this.getData();
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        getData() {
            if ((this.calculationPeriods.find(x => x.id === this.calculationPeriod).closed) && (this.calculationPeriods.find(x => x.id === this.calculationPeriod).profit_data != null)) {
                console.log('getting from saved data');
                this.profitData = this.calculationPeriods.find(x => x.id === this.calculationPeriod).profit_data
            } else {
                console.log('getting from api');
                projects
                    .getProfit(this.calculationPeriod)
                    .then((response) => {
                        this.profitData = response.data;
                        //console.log(this.dividendsData);
                    })
                    .catch((error) => {
                        this.$message({
                            message: "Не удалось загрузить данные: " + error,
                            type: "error",
                            duration: 5000,
                            showClose: true,
                        });
                    });
            }

        },

    },
};
</script>

<style scoped></style>
