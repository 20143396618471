<template>
    <el-container>
        <el-main class="outer">


            <el-card class="box-card">
                <el-form :model="credentials" label-width="80px" class="login-form">
                    <el-form-item label="Email">
                        <el-input v-model.number="credentials.email" type="email" autocomplete="on"></el-input>
                    </el-form-item>
                    <el-form-item label="Password">
                        <el-input type="password" v-model="credentials.password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item align="right">
                        <el-button type="primary" autofocus @click="login()">Login</el-button>
                    </el-form-item>
                </el-form>
            </el-card>


        </el-main>
    </el-container>
</template>

<script>
import { auth } from "../../api_connectors";

export default {
    name: "LoginIndex",
    data() {
        return {
            credentials: {}
        };
    },
    mounted() {

    },
    methods: {
        login() {
            auth
                .login(this.credentials)
                .then((response) => {
                    console.log(response.data);
                    // write user token
                    localStorage.user_token = response.data.token;
                    // redirect to app route
                    window.location.href = 'app';

                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось войти - проверьте email и пароль",
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.outer {
    position: relative;
}

.box-card {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -200px;
}
</style>
