<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Сотрудники</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-button v-if="$can('employees.write')" @click="newRow()" type="success"
                        icon="el-icon-plus">Создать</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>
            <el-table :data="dataRows">
                <el-table-column prop="name" label="ФИО">
                </el-table-column>
                <el-table-column prop="contract_start" label="Дата найма">
                </el-table-column>
                <el-table-column prop="contract_end" label="Дата увольнения">
                </el-table-column>
                <el-table-column prop="left_vacation" label="Неисп. дней отпуска">
                </el-table-column>
                <el-table-column label="Действия">
                    <template slot-scope="scope">
                        <el-button-group style="font-size: 20px">
                            <a :href="'/api/employees/' + dataRows[scope.$index].id + '/get-employee-contract'" target="_blanc"><i v-if="$can('employees.read')"
                                    class="el-icon-document" style="color: gray"></i></a>
                            <i v-if="$can('employees.write')" @click="editRow(scope.$index)" class="el-icon-edit"
                                style="color: blue"></i>
                            <el-popconfirm v-if="$can('employees.write')" @confirm="deleteRow(scope.$index)"
                                title="Точно хотите удалить?" confirm-button-text="Да" cancel-button-text="Нет">
                                <i slot="reference" style="color: red" class="el-icon-delete"></i>
                            </el-popconfirm>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog v-if="rowObject != null" title="Создать/Изменить" :visible.sync="createDialogVisible">
                <el-form :model="rowObject" label-width="160px">
                    <el-form-item label="ФИО">
                        <el-input v-model="rowObject.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Тип сотрудника">
                        <el-select v-model="rowObject.entity_type">
                            <el-option label="Самозанятый" value="self-employed"></el-option>
                            <el-option label="ИП" value="sole-proprietor"></el-option>
                            <el-option label="Оплата налом учредителями" value="cash"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Услуги по ...">
                        <el-input v-model="rowObject.services" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Паспорт ...">
                        <el-input v-model="rowObject.passport" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="ИНН">
                        <el-input v-model="rowObject.inn" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Адрес">
                        <el-input v-model="rowObject.address" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Расчетный счет">
                        <el-input v-model="rowObject.rs" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="БИК">
                        <el-input v-model="rowObject.bik" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Цена стори-поинта">
                        <el-input v-model="rowObject.story_point_price" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="План стори-поинтов">
                        <el-input v-model="rowObject.story_point_plan" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Дата найма">
                        <el-date-picker v-model="rowObject.contract_start" type="date" placeholder="Pick a day"
                            format="dd.MM.yyyy" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Дата увольнения">
                        <el-date-picker v-model="rowObject.contract_end" type="date" placeholder="Pick a day"
                            format="dd.MM.yyyy" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createDialogVisible = false">Отмена</el-button>
                    <el-button type="primary" @click="saveRow()">Сохранить</el-button>
                </span>
            </el-dialog>

        </el-main>
    </el-container>
</template>

<script>
import { employees } from "../../api_connectors";

export default {
    name: "EmployeesIndex",
    data() {
        return {
            dataRows: [],
            rowObject: null,
            createDialogVisible: false,

        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            employees
                .list()
                .then((response) => {
                    this.dataRows = response.data;
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        newRow() {
            this.rowObject = {
                name: null,
                contract_start: null,
                contract_end: null,
                entity_type: null,
                services: null,
                passport: null,
                inn: null,
                address: null,
                rs: null,
                bik: null,
                story_point_price: null,
                story_point_plan: null,
            };
            this.createDialogVisible = true;
        },
        editRow($index) {
            this.rowObject = this.dataRows[$index];
            this.createDialogVisible = true;
        },
        saveRow() {
            this.$loading();
            let result =
                this.rowObject.id > 0
                    ? employees.update(
                        this.rowObject.id,
                        this.rowObject
                    )
                    : employees.create(this.rowObject);
            result
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                    this.getData();
                    this.createDialogVisible = false;
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        deleteRow($index) {
            this.$loading();
            employees
                .delete(this.dataRows[$index].id)
                .then((response) => {
                    this.getData();
                    this.$loading().close();
                    this.$message({
                        message: "Успешное удаление!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        getContract($index) {
            this.$loading();
            employees
                .getEmployeeContract(this.dataRows[$index].id)
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Договор сгенерирован!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },

    },
};
</script>

<style scoped></style>
