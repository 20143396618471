<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Занятость</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-select v-model="calculationPeriod" @change="getOccupations">
                        <el-option v-for="item in calculationPeriods" :key="item.id"
                            :label="item.period_short + ((item.closed) ? ' - закрыт' : '')" :value="item.id"></el-option>
                    </el-select>
                    <!-- <el-button @click="getOccupations()" type="warning">Показать</el-button> -->
                    <el-button v-if="$can('occupations.write')" @click="saveOccupations()"
                        type="success">Сохранить</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>

            <el-table v-if="percentsData != null" :data="percentsData.data" style="width: 100%" height="100%">
                <el-table-column fixed prop="employee" label="Employee" width="150">
                </el-table-column>
                <el-table-column v-for="item in percentsData.columns" :key="item.id" :prop="item.id" :label="item.name">
                    <template slot-scope="scope">
                        <el-input v-model="percentsData.data[scope.$index][scope.column.property]"
                            :disabled="checkDisabled(calculationPeriod)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="Sum" width="150">
                    <template slot-scope="scope">
                        <span :class="summer(scope.$index).class">{{ summer(scope.$index).sum }} %</span>
                    </template>
                </el-table-column>
            </el-table>

        </el-main>
    </el-container>
</template>

<script>
import { occupations } from "../../api_connectors";
import { calculationPeriods } from "../../api_connectors";
var moment = require('moment');

export default {
    name: "TransactionsIndex",
    data() {
        return {
            calculationPeriods: [],
            calculationPeriod: null,
            percentsData: null,
        };
    },
    mounted() {
        this.getCalculationPeriods();
    },
    methods: {
        checkDisabled(checked_id) {
            return (this.calculationPeriods.find(x => x.id === checked_id).closed) ? true : false;
        },
        summer(index) {
            var sum = 0;
            var i;
            for (i = 1; i <= this.percentsData.columns.length; i++) {
                sum = sum + parseInt(this.percentsData.data[index]['pr' + i])
            }
            return {
                sum: sum,
                class: (sum == 100) ? "green" : "red"
            }

        },
        getCalculationPeriods() {
            calculationPeriods
                .list()
                .then((response) => {
                    this.calculationPeriods = response.data;
                    //console.log(moment().subtract(1, 'month').format('MM.YYYY'));
                    this.calculationPeriods.forEach(element => {
                        //console.log(element.period_short);
                        if (element.period_short == moment().subtract(1, 'month').format('MM.YYYY')) {

                            this.calculationPeriod = element.id
                        }
                    });
                    this.getOccupations();
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        getOccupations() {
            if (this.calculationPeriods.find(x => x.id === this.calculationPeriod).closed) {
                console.log('getting from saved data');
                this.percentsData = this.calculationPeriods.find(x => x.id === this.calculationPeriod).occupations_data
            } else {
                console.log('getting from api');
                occupations
                    .get(this.calculationPeriod)
                    .then((response) => {
                        this.percentsData = response.data;
                        this.percentsData.calculation_period = this.calculationPeriod;
                        console.log(this.percentsData);
                    })
                    .catch((error) => {
                        this.$message({
                            message: "Не удалось загрузить данные: " + error,
                            type: "error",
                            duration: 5000,
                            showClose: true,
                        });
                    });
            }
        },
        saveOccupations() {
            occupations
                .save(this.percentsData)
                .then((response) => {
                    console.log('success');
                    this.$message({
                        message: "Успешно сохранено",
                        type: "success",
                        duration: 5000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },


    },
};
</script>

<style scoped>
.red {
    font-weight: bold;
    color: crimson;
}

.green {
    font-weight: bold;
    color: darkgreen;
}
</style>
